import { useAuth0 } from '@auth0/auth0-react';
import { useState, useCallback, useEffect } from 'react';

const Reminders = () => {
  const { getAccessTokenSilently, isLoading } = useAuth0();
  const [reminders, setReminders] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const [addReminderForm, setAddReminderForm] = useState({
    label: '',
    due: '',
    notes: '',
    send: false,
  });

  const handleInputChange = (e) => {
    let newReminder = Object.assign({}, addReminderForm);
    newReminder[e.target.name] =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setAddReminderForm(newReminder);
  };
  const fetchData = useCallback(async () => {
    const token = await getAccessTokenSilently({
      audience: 'https://trackdebt.devcpu.com',
    });

    setIsFetching(true);

    const api = await fetch('/.netlify/functions/getReminders', {
      headers: {
        authorization: 'Bearer ' + token,
        scope: 'read:reminders',
      },
      method: 'GET',
    });

    const response = await api.json();

    if (response.success) {
      setReminders(response.response);
    } else {
      setReminders([]);
    }

    setIsFetching(false);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, [fetchData]);

  const addReminder = async (e) => {
    e.preventDefault();
    const token = await getAccessTokenSilently({
      audience: 'https://trackdebt.devcpu.com',
    });

    const api = await fetch('/.netlify/functions/addReminder', {
      headers: {
        authorization: 'Bearer ' + token,
        scope: 'write:reminders',
      },
      method: 'POST',
      body: JSON.stringify({ ...addReminderForm, send: addReminderForm.send ? true : null }),
    });

    const response = await api.json();

    if (response.success) {
      await fetchData();
      setAddReminderForm({
        label: '',
        due: '',
        notes: '',
        send: false
      });
    } else {
      setReminders([]);
    }
  };

  const markReminderComplete = (id) => async () => {
    if (!window.confirm('Are you sure you want to mark this as paid?')) {
      return;
    }

    const token = await getAccessTokenSilently({
      audience: 'https://trackdebt.devcpu.com',
    });

    const api = await fetch(`/.netlify/functions/deleteReminder?id=${id}`, {
      headers: {
        authorization: 'Bearer ' + token,
        scope: 'write:reminders',
      },
      method: 'DELETE',
    });

    const response = await api.json();

    if (response.success) {
      await fetchData();
    }
  };

  if (isLoading || isFetching) {
    return <section aria-busy={true}>Loading...</section>;
  }

  return (
    <section id="tables">
      <h2>Reminders List</h2>
      <table role="grid">
        <thead>
          <tr>
            <th scope="col">Label</th>
            <th scope="col">Notes</th>
            <th scope="col">Created</th>
            <th scope="col">Due Date</th>
            <th scope="col">Notify</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        {reminders &&
          reminders.map((r) => (
            <tbody>
              <tr>
                <th scope="row">{r.label}</th>
                <td>{r.notes}</td>
                <td>{new Date(r.createdat).toDateString()}</td>
                <td title={r.duedate}>{new Date(r.duedate).toDateString()}</td>
                <td>
                  {r.sendreminder !== null ? r.sendreminder.toString() : ''}
                </td>
                <td>
                  <button type="button" onClick={markReminderComplete(r.id)}>Completed</button>
                </td>
              </tr>
            </tbody>
          ))}
      </table>
      {(!reminders || !reminders.length) && (
        <div>
          <strong>No results, add a reminder to view here.</strong>
          <hr />
        </div>
      )}

      <figure>
        <progress value={100} max={100} />
      </figure>

      <figure>
        <details>
          <summary>Add Reminder</summary>

          <form onSubmit={addReminder}>
            <label htmlFor="label">Label</label>
            <input
              type="text"
              id="label"
              name="label"
              placeholder="Walk the cat"
              maxLength={250}
              onChange={handleInputChange}
              value={addReminderForm.label}
            />

            <label htmlFor="notes">Notes</label>
            <input
              type="text"
              id="notes"
              name="notes"
              placeholder="Go on seven acres, walk past the gate, avoid dogs"
              onChange={handleInputChange}
              value={addReminderForm.notes}
            />

            <label htmlFor="due">
              Due date
              <input
                type="datetime-local"
                id="due"
                name="due"
                onChange={handleInputChange}
                value={addReminderForm.due}
              />
            </label>

            <fieldset>
              <label for="send">
                <input
                  type="checkbox"
                  role="switch"
                  id="send"
                  name="send"
                  onChange={handleInputChange}
                  checked={addReminderForm.send}
                />
                Send reminder?
              </label>
            </fieldset>

            <button type="submit">Add</button>
          </form>
        </details>
      </figure>
    </section>
  );
};

export default Reminders;
