import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const Nav = () => {
  const { loginWithRedirect, logout, user, isAuthenticated, isLoading } =
    useAuth0();

  return (
    <nav>
      <ul>
        <li>
          <strong>DevCPU</strong>
        </li>
      </ul>
      <ul>
        <li>
          <details role="list" dir="rtl">
            <summary role="link">Apps</summary>
            <ul role="listbox">
              <li>
                <Link to="/debts">Debts</Link>
              </li>
              <li>
                <Link to="/reminders">Reminders</Link>
              </li>
            </ul>
          </details>
        </li>
        <li>
          {user && isAuthenticated ? (
            <details role="list" dir="rtl">
              <summary role="link">
                {user.picture ? (
                  <img
                    src={user.picture}
                    width="24"
                    height="24"
                    alt={user.name}
                  />
                ) : (
                  user.name
                )}
              </summary>
              <ul role="listbox">
                <li>
                  <a href="/#logout" onClick={() => logout()}>
                    Log out
                  </a>
                </li>
              </ul>
            </details>
          ) : (
            <a
              href="#/login"
              onClick={() => loginWithRedirect()}
              aria-busy={isLoading ? true : false}
            >
              {!isLoading && 'Log in'}
            </a>
          )}
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
