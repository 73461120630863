import React, { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { jsPDF } from 'jspdf';

function groupBy(objectArray, property) {
  return objectArray.reduce(function (acc, obj) {
    var key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
}

const Debts = () => {
  const { getAccessTokenSilently, isLoading } = useAuth0();

  const [debts, setDebts] = useState([]);

  const [isFetchingDebts, setIsFetchingDebts] = useState(true);

  const [addDebtForm, setAddDebtform] = useState({
    name: '',
    amount: '',
    notes: '',
  });

  const fetchData = useCallback(async () => {
    const token = await getAccessTokenSilently({
      audience: 'https://trackdebt.devcpu.com',
    });

    const api = await fetch('/.netlify/functions/getDebts', {
      headers: {
        authorization: 'Bearer ' + token,
        scope: 'read:debts',
      },
      method: 'GET',
    });

    const response = await api.json();

    if (response.success) {
      setDebts(response.response);
    } else {
      setDebts([]);
    }

    setIsFetchingDebts(false);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, [fetchData]);

  const addDebt = async (name, amount, notes) => {
    const token = await getAccessTokenSilently({
      audience: 'https://trackdebt.devcpu.com',
    });

    const api = await fetch('/.netlify/functions/addDebt', {
      headers: {
        authorization: 'Bearer ' + token,
        scope: 'write:debts',
      },
      method: 'POST',
      body: JSON.stringify({ name, amount, notes }),
    });

    const response = await api.json();

    if (response.success) {
      await fetchData();
      setAddDebtform({ name: '', amount: '', notes: '' });
    } else {
      setDebts([]);
    }
  };

  const markDebtPaid = (id) => async () => {
    if (!window.confirm('Are you sure you want to mark this as paid?')) {
      return;
    }

    const token = await getAccessTokenSilently({
      audience: 'https://trackdebt.devcpu.com',
    });

    const api = await fetch(`/.netlify/functions/deleteDebt?id=${id}`, {
      headers: {
        authorization: 'Bearer ' + token,
        scope: 'write:debts',
      },
      method: 'DELETE',
    });

    const response = await api.json();

    if (response.success) {
      await fetchData();
    }
  };

  const handleInputChange = (e) => {
    let newDebt = Object.assign({}, addDebtForm);
    newDebt[e.target.name] = e.target.value;
    setAddDebtform(newDebt);
  };

  const submitAddDebt = async (e) => {
    e.preventDefault();
    await addDebt(addDebtForm.name, addDebtForm.amount, addDebtForm.notes);
  };

  if (isLoading || isFetchingDebts) {
    return <section aria-busy={true}>Loading...</section>;
  }

  const generatePdf = (name) => {
    const debtsToPrint = [...debts.filter((x) => x.name === name)].sort(
      (a, b) => a.amount - b.amount
    );
    const doc = new jsPDF();
    let yPixels = 10;

    const currentDate = new Date();

    doc
      .setFont('helvetica', 'bold')
      .text(
        `Debts for ${name} - Generated: ${currentDate.toDateString()}`,
        10,
        yPixels
      )
      .setFont('helvetica', 'normal');

    debtsToPrint.forEach((debt, i) =>
      doc.text(`£${debt.amount} - ${debt.notes}`, 10, yPixels + ++i * 10)
    );

    const totalDebt = debtsToPrint
      .map((x) => parseFloat(x.amount))
      .reduce((a, b) => a + b)
      .toFixed(2);

    doc
      .setFont('helvetica', 'bold')
      .text(`£${totalDebt}`, 10, yPixels + 10 * debtsToPrint.length + 10);

    doc.save(`${name}-debts-${currentDate.getTime()}.pdf`);
  };

  return (
    <section id="tables">
      <h2>Debt List</h2>

      {debts &&
        Object.entries(groupBy(debts, 'name')).map((e) => (
          <figure key={e[0]}>
            <details>
              <summary>
                {e[0]} - £
                {e[1]
                  .map((x) => parseFloat(x.amount))
                  .reduce((a, b) => a + b)
                  .toFixed(2)}
              </summary>
              <table role="grid">
                <thead>
                  <tr>
                    <th scope="col">Amount</th>
                    <th scope="col">Notes</th>
                    <th scope="col">Created</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {e[1] &&
                    e[1].map((debt) => (
                      <tr key={debt.id}>
                        <th scope="row">£{debt.amount}</th>
                        <td>{debt.notes}</td>
                        <td>{new Date(debt.createdat).toDateString()}</td>
                        <td>
                          <button type="button" onClick={markDebtPaid(debt.id)}>
                            Paid
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <button type="button" onClick={() => generatePdf(e[0])}>
                Print PDF
              </button>
            </details>
          </figure>
        ))}

      {(!debts || !debts.length) && (
        <div>
          <strong>No results, add a debt to view here.</strong>
          <hr />
        </div>
      )}

      <figure>
        <progress value={100} max={100} />
      </figure>

      <figure>
        <details>
          <summary>Add Debt</summary>

          <form onSubmit={submitAddDebt}>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="John Smith"
              onChange={handleInputChange}
              value={addDebtForm.name}
            />

            <label htmlFor="amount">Amount</label>
            <input
              type="number"
              id="amount"
              name="amount"
              placeholder="£69"
              onChange={handleInputChange}
              value={addDebtForm.amount}
            />

            <label htmlFor="notes">Notes</label>
            <input
              type="text"
              id="notes"
              name="notes"
              placeholder="Pizza and hookers"
              onChange={handleInputChange}
              value={addDebtForm.notes}
            />

            <button type="submit">Add</button>
          </form>
        </details>
      </figure>
    </section>
  );
};

export default Debts;
