import { Routes, Route } from 'react-router-dom';
import ProtectedComponent from './components/ProtectedComponent/ProtectedComponent';
import Debts from './pages/Debts';
import Reminders from './pages/Reminders';
import Home from './pages/Home';

function App() {
  return (
    <main>
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route
          path="/debts"
          element={<ProtectedComponent component={Debts} />}
          exact
        />
        <Route
          path="/reminders"
          element={<ProtectedComponent component={Reminders} />}
          exact
        />
      </Routes>
    </main>
  );
}

export default App;
